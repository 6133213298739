import Vue from 'vue'
import App from '@/App.vue'
import i18n from '@/i18n'
import router from '@/router'
import store from '@/store'
import { apiHost } from '@/config'
import titleMixin from '@/mixins/titleMixin'
Vue.mixin(titleMixin)

import axios from 'axios'
axios.defaults.withCredentials = true
axios.defaults.baseURL = apiHost

import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
VuexORM.use(VuexORMAxios, { axios })

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false

import GoBack from "@/components/GoBack"
Vue.component('GoBack', GoBack)
import VueMarkdown from '@adapttive/vue-markdown'
Vue.component('vue-markdown', VueMarkdown)

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')