import { Model } from '@vuex-orm/core'
import Document from './Document'
import Documentable from './Documentable'
import Unit from './Unit'
import Weblink from './Weblink'

export default class Location extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'locations'

  static fields () {
    return {
      id: this.attr(null),
      name: this.string(''),
      parent_location_id: this.attr(null),

      weblinks: this.morphMany(Weblink, 'weblinkable_id', 'weblinkable_type'),
      units: this.hasMany(Unit, 'location_id'),
      children: this.hasMany(Location, 'parent_location_id'),
      parent: this.belongsTo(Location, 'parent_location_id'),
      documents: this.morphToMany(Document, Documentable, 'document_id', 'documentable_id', 'documentable_type'),
    }
  }

  static fetch () {
    return this.api().get(`/api/locations`)
  }
  static fetchById (id) {
    return this.api().get(`/api/locations/${id}`)
  }
}