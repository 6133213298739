import i18n from '@/i18n'

let baseUrl = process.env.VUE_APP_API_BASE_URL
let baseDomain = 'byt-online.cz'
let brandName = i18n.t('brand.'+baseDomain.replace(/\./g, ''))

switch (window.location.hostname) {
  case 'byt-online.cz':
  case 'www.byt-online.cz':
    baseUrl = 'https://api.byt-online.cz'
    baseDomain = 'byt-online.cz'
    break;
  case 'localhost':
    baseUrl = 'http://localhost:8000'
    brandName = i18n.t('brand.localhost')
    break;
}

export const apiHost = baseUrl
export const eapDomain = baseDomain
export const brand = brandName