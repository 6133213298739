import { Model } from '@vuex-orm/core'

export default class Applianceable extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'applianceables'

  static fields () {
    return {
      appliance_id: this.attr(null),
      applianceable_id: this.attr(null),
      applianceable_type: this.attr(null),
    }
  }
}