import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import VuexORM from '@vuex-orm/core'

import auth from "./modules/auth";

import Appliance from '@/models/Appliance'
import ApplianceType from '@/models/ApplianceType'
import Document from '@/models/Document'
import Documentable from '@/models/Documentable'
import File from '@/models/File'
import Location from '@/models/Location'
import Unit from '@/models/Unit'
import User from '@/models/User'
import Weblink from '@/models/Weblink'

const database = new VuexORM.Database()

database.register(Appliance)
database.register(ApplianceType)
database.register(Document)
database.register(Documentable)
database.register(File)
database.register(Location)
database.register(Unit)
database.register(User)
database.register(Weblink)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
  },
  plugins: [VuexORM.install(database)],
})