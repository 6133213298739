import { Model } from '@vuex-orm/core'

export default class Weblink extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'weblinks'

  static fields () {
    return {
      id: this.attr(null),
      weblinkable_id: this.attr(null),
      weblinkable_type: this.attr(null),
      weblinkable: this.morphTo('weblinkable_id', 'weblinkable_type'),
      title: this.attr(''),
      url: this.attr(''),
    }
  }

  static fetch () {
    return this.api().get(`/api/weblinks`)
  }
  static fetchById (id) {
    return this.api().get(`/api/weblinks/${id}`)
  }
}