<template>
  <div class="container">
    <h1>{{ $t('profile.loggedinas', {name: user.name}) }}</h1>
    <vue-markdown v-if="$te('profile.intro')" :source="$t('profile.intro', {
      brand: brand,
      name: user.name,
      username: user.username,
      email: user.email
    })"></vue-markdown>
    <pre v-if="devmode">{{ user }}</pre>
    <b-table stacked :items="[this.user]" :fields="fields"></b-table>
  </div>
</template>

<script>
import { brand } from '@/config'
import { mapGetters } from 'vuex'

export default {
  name: 'ProfilePage',
  title () {
    return this.$t('profile.title')
  },
  data () {
    return {
      brand: brand,
      devmode: process.env.NODE_ENV === 'development',
      fields: [
        {key: 'id', label: this.$t('profile.table.id')},
        {key: 'name', label: this.$t('profile.table.name')},
        {key: 'username', label: this.$t('profile.table.username')},
        {key: 'email', label: this.$t('profile.table.email')},
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    })
  }
}
</script>