import { Model } from '@vuex-orm/core'

export default class File extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'files'

  static fields () {
    return {
      id: this.attr(null),

      fileable_id: this.attr(null),
      fileable_type: this.attr(null),

      path: this.attr(null),
      name: this.string(''),
    }
  }

  static fetch () {
    return this.api().get(`/api/files`)
  }
  static fetchById (id) {
    return this.api().get(`/api/files/${id}`)
  }
}