import axios from 'axios';

export default {
  namespaced: true,

  state: {
    user: null
  },

  getters: {
    authenticated (state) {
      return !!(state.user)
    },

    user (state) {
      return state.user
    },
  },

  mutations: {
    SET_USER (state, value) {
      state.user = value
    }
  },

  actions: {
    async login ({ dispatch }, credentials) {
      await axios.get('/sanctum/csrf-cookie').then(async () => {
        await axios.post('/login', credentials)
      })
      return dispatch('me')
    },

    async logout ({ dispatch }) {
      await axios.get('/sanctum/csrf-cookie').then(async () => {
        await axios.post('/logout')
      })
      return dispatch('me')
    },

    async register ({ dispatch }, userData) {
      await axios.get('/sanctum/csrf-cookie').then(async () => {
        await axios.post('/register', userData)
      })
      return dispatch('me')
    },

    me ({ commit }) {
      return axios.get('/api/user/me').then((response) => {
        commit('SET_USER', response.data)
      }).catch(() => {
        commit('SET_USER', null)
      })
    }
  }
}