import { Model } from '@vuex-orm/core'
import Document from './Document'
import Documentable from './Documentable'
import Location from './Location'
import Weblink from './Weblink'

export default class Unit extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'units'

  static fields () {
    return {
      id: this.attr(null),
      location_id: this.attr(null),
      type: this.attr(null),
      floor: this.attr(0),
      number: this.attr(null),
      project_number: this.attr(null),
      name: this.attr(''),
      part: this.attr(null),

      weblinks: this.morphMany(Weblink, 'weblinkable_id', 'weblinkable_type'),
      location: this.belongsTo(Location, 'location_id'),
      documents: this.morphToMany(Document, Documentable, 'document_id', 'documentable_id', 'documentable_type'),
    }
  }

  static fetch () {
    return this.api().get(`/api/units`)
  }
  static fetchById (id) {
    return this.api().get(`/api/units/${id}`)
  }
}