<template>
  <div class="container">
    <b-jumbotron :header="$t('dashboard.title')" :lead="$t('dashboard.lead', {
      brand: brand,
      name: user.name,
      email: user.email,
      username: user.username,
    })">
    <vue-markdown v-if="$te('dashboard.intro')" :source="$t('dashboard.intro', {
      brand: brand,
      name: user.name,
      email: user.email,
      username: user.username,
    })"></vue-markdown>
    </b-jumbotron>
  </div>
</template>

<script>
import { brand } from '@/config'
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardPage',
  title () {
    return this.$t('dashboard.title')
  },
  data () {
    return {
      brand: brand,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    })
  }
}
</script>