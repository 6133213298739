import { Model } from '@vuex-orm/core'

export default class Documentable extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'documentables'

  static fields () {
    return {
      document_id: this.attr(null),
      documentable_id: this.attr(null),
      documentable_type: this.attr(null),
    }
  }
}