<template>
  <div class="container">
    <b-form @submit.prevent="submit">
      <h1>{{ $t('login.title') }}</h1>
      <b-form-group
        :label="$t('login.label.email')"
        label-for="email"
      >
        <b-form-input
          id="email"
          v-model="form.email"
          type="email"
          required
          :placeholder="$t('login.placeholder.email')"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        :label="$t('login.label.password')"
        label-for="password"
      >
        <b-form-input
          id="password"
          v-model="form.password"
          type="password"
          required
          :placeholder="$t('login.placeholder.password')"
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary">{{ $t('login.button.login') }}</b-button>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LoginPage',
  title () {
    return this.$t('login.title')
  },
  data() {
    return {
      form: {
        email: '',
        password: '',        
      }
    }
  },
  methods: {
    ...mapActions({
      login: 'auth/login'
    }),
    async submit(evt) {
      evt.preventDefault()

      await this.login(this.form)

      this.$router.replace({ name: 'dashboard' })
    }
  }
}
</script>