import { Model } from '@vuex-orm/core'
import Appliance from './Appliance'
import ApplianceType from './ApplianceType'
import Documentable from './Documentable'
import File from './File'
import Location from './Location'
import Unit from './Unit'

export default class Document extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'documents'

  static fields () {
    return {
      id: this.attr(null),
      name: this.string(''),
      description: this.attr(null),
      meta: this.attr(null),
      type: this.attr(null),

      appliances: this.morphedByMany(Appliance, Documentable, 'document_id', 'documentable_id', 'documentable_type'),
      appliancetypes: this.morphedByMany(ApplianceType, Documentable, 'document_id', 'documentable_id', 'documentable_type'),
      files: this.morphMany(File, 'fileable_id', 'fileable_type'),
      locations: this.morphedByMany(Location, Documentable, 'document_id', 'documentable_id', 'documentable_type'),
      units: this.morphedByMany(Unit, Documentable, 'document_id', 'documentable_id', 'documentable_type'),
    }
  }

  static fetch () {
    return this.api().get(`/api/documents`)
  }
  static fetchById (id) {
    return this.api().get(`/api/documents/${id}`)
  }
}