<template>
  <div class="container">
    <b-jumbotron :header="$t('home.title')" :lead="$t('home.lead', {brand: brand})">
      <vue-markdown v-if="$te('home.intro')" :source="$t('home.intro', {brand: brand})"></vue-markdown>
      <b-button variant="primary" :to="{ name: 'login'}">{{ $t('home.login') }}</b-button>
    </b-jumbotron>
  </div>
</template>

<script>
import { brand } from '@/config'

export default {
  name: 'HomePage',
  title () {
    return this.$t('home.title')
  },
  data () {
    return {
      brand: brand
    }
  },
}
</script>