<template>
  <b-button variant="link" @click="goBack">
    <b-icon icon="chevron-left" ></b-icon>
    {{ $t('common.goback') }}
  </b-button>
</template>

<script>
export default {
  methods: {
    goBack() {
      return this.$router.go(-1)
    }
  }
}
</script>