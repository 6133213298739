var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('b-jumbotron',{attrs:{"header":_vm.$t('dashboard.title'),"lead":_vm.$t('dashboard.lead', {
    brand: _vm.brand,
    name: _vm.user.name,
    email: _vm.user.email,
    username: _vm.user.username,
  })}},[(_vm.$te('dashboard.intro'))?_c('vue-markdown',{attrs:{"source":_vm.$t('dashboard.intro', {
    brand: _vm.brand,
    name: _vm.user.name,
    email: _vm.user.email,
    username: _vm.user.username,
  })}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }