import { Model } from '@vuex-orm/core'

export default class User extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'users'

  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(''),
      username: this.attr(''),
      email: this.attr(''),
      email_verified_at: this.attr(''),
      eap_password: this.attr(''),
    }
  }

  static fetch () {
    return this.api().get(`/api/users`)
  }
  static fetchById (id) {
    return this.api().get(`/api/users/${id}`)
  }
  static fetchMe () {
    return this.api().get(`/api/user/me`)
  }
}