import { Model } from '@vuex-orm/core'
import ApplianceType from './ApplianceType'
import Applianceable from './Applianceable'
import Document from './Document'
import Documentable from './Documentable'
import Location from './Location'
import Unit from './Unit'

export default class Appliance extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'appliances'

  static fields () {
    return {
      id: this.attr(null),
      name: this.string(''),
      model: this.string(''),

      documents: this.morphToMany(Document, Documentable, 'document_id', 'documentable_id', 'documentable_type'),
      locations: this.morphedByMany(Location, Applianceable, 'appliance_id', 'applianceable_id', 'applianceable_type'),
      type: this.belongsTo(ApplianceType),
      units: this.morphedByMany(Unit, Applianceable, 'appliance_id', 'applianceable_id', 'applianceable_type'),
    }
  }

  static fetch () {
    return this.api().get(`/api/appliances`)
  }
  static fetchById (id) {
    return this.api().get(`/api/appliances/${id}`)
  }
}