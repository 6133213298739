import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Home from '@/views/home.vue'
import Login from '@/views/login.vue'
import Dashboard from '@/views/dashboard.vue'
import Profile from '@/views/profile.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { requiresVisitor: true },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { requiresVisitor: true },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: { requiresAuth: true },
  },
  {
    path: '/bulletinboard',
    name: 'bulletinboard.index',
    component: () => import('@/views/bulletinboard.index.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/documents',
    name: 'document.index',
    component: () => import('@/views/document.index.vue'),
    meta: { requiresAuth: true },
    props(route) {
      return {
        selectedUnits: parseInt(route.params.units)
      }
    },
  },
  {
    path: '/documents/:id',
    name: 'document.show',
    component: () => import('@/views/document.show.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/locations',
    name: 'location.index',
    component: () => import('@/views/location.index.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/units',
    name: 'unit.index',
    component: () => import('@/views/unit.index.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/weblinks',
    name: 'weblink.index',
    component: () => import('@/views/weblink.index.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/wifi',
    name: 'wifi.index',
    component: () => import('@/views/wifi.index.vue'),
    meta: { requiresAuth: true },
  },
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})

async function checkAuth() {
  if (store.getters['auth/authenticated'] !== true) {
    await store.dispatch('auth/me')
  }
  return store.getters['auth/authenticated']
}

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!(await checkAuth())) {
      next({
        name: 'login',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (await checkAuth()) {
      next({
        name: 'dashboard',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
